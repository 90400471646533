import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import logo from "../assets/BusinessPhoto Logo Better 2.png";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Stepper, Step, StepLabel } from "@mui/material";
import GenderStep from "./GenderStep.js";
import PhotoUploadStep from "./PhotoUploadStep";
import DOMPurify from "dompurify";
import { startTraining, uploadImagesToS3 } from "./services.js";

import "./GetYourPhotos2.css"; // Assume you have a CSS file for styling
import SummaryStep from "./SummaryStep.js";
import CustomButton from "./Button.js";
// import useTikTokPixel from "../hooks/useTikTokPixel.js";
import { useQueryParams } from "../hooks/useQueryParams.js";
import VideoStep from "./VideoStep.js";
import Loader from "./Loader.js";

const REACT_APP_DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE === "true";
const REACT_APP_TEST_MODE = process.env.REACT_APP_TEST_MODE === "true";

const GetYourPhotos2 = () => {
  const steps = [
    "Select Gender",
    "How To Select Photos",
    "Upload Photos",
    "Summary",
  ];
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [hasCheckedPayment, setHasCheckedPayment] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pricing, setPricing] = useState("");
  // const [trackEvent] = useTikTokPixel();
  const [enableNext, setEnableNext] = useState(true); // This will control the disabled state of the button
  const [countdown, setCountdown] = useState(30); // Initialize countdown

  const queryParams = useQueryParams();
  const sessionID = useMemo(() => queryParams.get("session_id"), [queryParams]);

  const getWebUrl = () => {
    if (REACT_APP_TEST_MODE) {
      return "https://photos-ai-web-df3fcc.webflow.io";
    }

    return "https://businessphoto.ai";
  };

  const checkPaymentStatus = async (userEmail, idToken) => {
    setHasCheckedPayment(true);
    const url = REACT_APP_TEST_MODE
      ? "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/web_get_has_paid_test"
      : "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/web_get_has_paid";

    let result = "False";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },

        body: JSON.stringify({ session_id: sessionID }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      result = data;
      setPricing(data.pricing);
      if (REACT_APP_DEBUG_MODE) console.log("Response: ", data);
    } catch (error) {
      console.error("Fetch error: ", error);
    }
    setHasCheckedPayment(false);
    return result;
  };

  // This effect is for the countdown
  useEffect(() => {
    let timer;
    if (step === 1) {
      if (countdown > 0) {
        // Continue the countdown
        timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);
      } else {
        // Countdown has finished, enable the Next button
        setEnableNext(true);
      }
    } else {
      // Reset countdown if not on step 1 and ensure Next button is disabled
      setCountdown(30);
      setEnableNext(false);
    }

    return () => clearTimeout(timer); // Cleanup timer
  }, [step, countdown]); // This effect depends on `sessionID`

  // This effect will run once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      if (!sessionID) {
        window.location.href = getWebUrl();
        return;
      }
      const result = await checkPaymentStatus("", "Token", sessionID);
      if (result.has_paid === "True") {
        setEmail(result.email);
      } else {
        window.location.href = getWebUrl();
      }
    };
    fetchData(); // Call fetchData directly here
  }, []);

  const initialValues = {
    gender: "",
    package: "",
    photos: [],
    selectedPricing: "",
  };

  const renderStep = (step, values, setFieldValue) => {
    const components = {
      0: <GenderStep values={values} setFieldValue={setFieldValue} />,
      1: <VideoStep />,
      2: <PhotoUploadStep values={values} setFieldValue={setFieldValue} />,
      3: <SummaryStep values={values} />,
    };

    return components[step];
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (step === 3) {
      setIsLoading(true);

      // Final submission logic here
      if (REACT_APP_DEBUG_MODE) console.log("Final Submission", values);
      const jsonValues = JSON.stringify(values);

      if (REACT_APP_DEBUG_MODE) console.log("Form Data as JSON:", jsonValues);
      const jobId = crypto.randomUUID();
      let num_images = "10";

      if (REACT_APP_DEBUG_MODE) console.log("Server Pricing", pricing);

      switch (pricing) {
        case "lite":
          num_images = "10";
          break;
        case "standard":
          num_images = "20";
          break;
        case "premium":
          num_images = "30";
          break;
        default:
          // You can handle the default case here if needed
          break;
      }

      if (REACT_APP_DEBUG_MODE) console.log("num_images AFTER: ", num_images);
      await new Promise((resolve) => setTimeout(resolve, 5000));

      try {
        // First, upload images to S3
        await uploadImagesToS3(values.photos, email, jobId, "");
        // Run the start training function
        const payload2 = {
          user_id: email,
          gender: values.gender,
          packages: ["package_professional"],
          email: email,
          job_id: jobId,
          num_images: num_images,
          render_mode: "txt2img",
          age: "20",
          transaction_id: "1234",
          tier: "express",
          app_bundle_id: "Web",
          promo_code: "",
          is_web: true,
          session_id: sessionID,
        };

        if (REACT_APP_DEBUG_MODE)
          console.log("Payload Start Training: ", payload2);
        await startTraining(payload2, "");
        // Set has paid of user to False once the flow is finished once
        // await send_email(firstName, email);

        window.location.href = `${getWebUrl()}/utility-page/email-confirmation`;
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Sorry, something went wrong. Please try again later.");
      } finally {
        setIsLoading(false);
      }

      // Now you can use `content` as a zip file containing all the photos
      // You might want to upload it somewhere or handle it according to your needs
      // navigate("/success");
    } else {
      setStep(step + 1);
    }
    setSubmitting(false);
  };

  return (
    <div>
      {hasCheckedPayment ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          <div className="get-your-photos">
            <div className="nav-bar">
              <img src={logo} alt="BusinessPhotoAI Logo" className="logo" />
              {/* Replace with your logo path */}
            </div>
            <Stepper activeStep={step} className="stepper-container">
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "black", // circle color (COMPLETED)
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                      {
                        color: "white", // Just text label (COMPLETED)
                      },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "black", // circle color (ACTIVE)
                    },
                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                      {
                        color: "white", // Just text label (ACTIVE)
                      },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "white", // circle's number (ACTIVE)
                    },
                    "& .MuiStepLabel-label": {
                      fontFamily: "'Thiccboi', sans-serif", // Change 'Your Font' to the name of your font
                    },
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              // validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={step}
                    timeout={500} // Duration of your animation
                    classNames="fade" // Your custom CSS class for the animation
                    unmountOnExit
                  >
                    <Form>
                      <div className="steps-container">
                        {!isLoading && renderStep(step, values, setFieldValue)}
                        {isLoading && (
                          <div className="loading-container">
                            <Loader />
                          </div>
                        )}
                      </div>
                      {/* Conditionally render the Next/Submit button */}
                      {!isLoading && (
                        <div className="buttons-container">
                          {step === 1 && !enableNext ? (
                            <CustomButton
                              label={`Wait ${countdown}s`}
                              style={{
                                fontFamily: "Thiccboi, sans-serif",
                              }}
                              disabled={true}
                            />
                          ) : (
                            <CustomButton
                              label={step === 3 ? "Submit" : "Next"}
                              type="submit"
                              style={{
                                fontFamily: "Thiccboi, sans-serif",
                              }}
                              disabled={
                                (step === 0 && values.gender === "") ||
                                (step === 2 && values.photos.length < 8) ||
                                (step === 1 && !enableNext)
                              }
                            />
                          )}

                          {step > 0 && (
                            <CustomButton
                              label="Back"
                              onClick={() => setStep(step - 1)}
                              style={{
                                fontFamily: "Thiccboi, sans-serif",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Form>
                  </CSSTransition>
                </SwitchTransition>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetYourPhotos2;
