import React from "react";
import "./HowItWorks.css";

const REACT_APP_TEST_MODE = process.env.REACT_APP_TEST_MODE === "true";
const refundPolicyUrl = REACT_APP_TEST_MODE 
  ? "https://photos-ai-web-df3fcc.webflow.io/refund-policy" 
  : "https://www.businessphoto.ai/refund-policy";

const VideoStep = () => {
  return (
    <div className="summary-step">
      <h2>How It Works</h2>
      <p>
        Creating your professional headshots is easy. All you need to do is
        upload some photos of yourself. We'll take care of the rest!
      </p>
      <b>Please watch the video below to achieve the best results.</b>
      <p>
        Following the instructions in the video is important, as it helps us provide the highest quality service and ensures your eligibility for our <a href={refundPolicyUrl} target="_blank" rel="noopener noreferrer">refund policy</a>.
      </p>
      {/* YouTube Video Embed */}
      <div className="video-container" style={{ marginTop: "20px" }}>
        <iframe
          width={"100%"}
          height="315"
          src="https://www.youtube.com/embed/EjIwtT4u_uc?si=zWidiSwStP-bNvAC"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoStep;
