import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone"; // Remember to install 'react-dropzone'
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader"; // Import a spinner from react-spinners
import "./PhotoUploadStep.css";
import heic2any from "heic2any";

// Assuming the assets are correctly imported
import goodExample1 from "../assets/photo_good_01.jpg";
import goodExample2 from "../assets/photo_good_02.jpg";
import goodExample3 from "../assets/photo_good_03.jpg";
import badExample1 from "../assets/photo_bad_01.jpg";
import badExample2 from "../assets/photo_bad_02.jpg";
import badExample3 from "../assets/photo_bad_03.jpg";
import Loader from "./Loader";

const PhotoUploadStep = () => {
  const { setFieldValue, values } = useFormikContext();
  const [fileCount, setFileCount] = useState(values.photos?.length ?? 0); // State for the count of files
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsLoading(true); // Start loading indicator
      const allFilesValid = acceptedFiles.every((file) =>
        ["image/jpeg", "image/png", "image/heic"].includes(file.type)
      );

      if (
        allFilesValid &&
        acceptedFiles.length >= 8 &&
        acceptedFiles.length <= 20
      ) {
        const convertHeicFiles = acceptedFiles.map((file) => {
          if (file.type === "image/heic") {
            return new Promise((resolve, reject) => {
              heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.9, // Adjust quality as needed
              })
                .then((response) => {
                  // Create a new File object from the Blob
                  const newFile = new File(
                    [response],
                    file.name.replace(/\.heic$/i, ".jpg"),
                    {
                      type: "image/jpeg",
                      lastModified: new Date(),
                    }
                  );
                  resolve(newFile);
                })
                .catch(reject);
            });
          } else {
            return Promise.resolve(file); // Return non-HEIC files unchanged
          }
        });

        Promise.all(convertHeicFiles).then((convertedFiles) => {
          setFieldValue("photos", convertedFiles);
          setFileCount(convertedFiles.length); // Update the file count state
          setIsLoading(false); // Stop loading indicator
        });
      } else {
        // Handle invalid file types or count
        let errorMessage =
          "Please select a minimum of 8 and a maximum of 20 photos.";
        if (!allFilesValid) {
          errorMessage =
            "Invalid file type detected. Please upload only PNG, JPEG, or HEIC files.";
        }
        alert(errorMessage);
        setIsLoading(false); // Stop loading indicator in case of error
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/heic",
    minSize: 0,
    maxSize: 20 * 1024 * 1024, // 20 MB
    maxFiles: 20,
  });

  return (
    <div className="photo-upload-container">
      <h2>Upload Photos</h2>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <Loader />
        ) : isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <FontAwesomeIcon icon={faPlusCircle} size="3x" />
            <p>Choose photos or drag them here.</p>
            <small>
              Maximum 20 photos. Supported formats: PNG, JPEG, HEIC.
            </small>
          </>
        )}
      </div>
      {!isLoading && fileCount > 0 && (
        <p className="file-upload-status">
          {fileCount} photo{fileCount > 1 ? "s" : ""} uploaded.
        </p>
      )}
      <h2>✅ Good Photo Examples</h2>
      <div className="image-grid">
        <img
          src={goodExample1}
          alt="Good Example 1"
          className="example-image"
        />
        <img
          src={goodExample2}
          alt="Good Example 2"
          className="example-image"
        />
        <img
          src={goodExample3}
          alt="Good Example 3"
          className="example-image"
        />
      </div>
      <p>
        Close-up selfies, same person, adults, variety of backgrounds, facial
        expressions, head tilts, and angles.
      </p>
      <h2>❌ Bad Examples</h2>
      <div className="image-grid">
        <img src={badExample1} alt="Bad Example 1" className="example-image" />
        <img src={badExample2} alt="Bad Example 2" className="example-image" />
        <img src={badExample3} alt="Bad Example 3" className="example-image" />
      </div>
      <p>
        Group photos, full lengths, hair blocking eyes, kids, covered faces,
        animals, monotonous pics, nudes, sunglasses.
      </p>
    </div>
  );
};

export default PhotoUploadStep;
