import React from "react";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";

const Loader = ({
  width = "80",
  height = "80",
  color = "#000000",
  style = {},
}) => {
  return (
    <TailSpin
      visible={true}
      height={height}
      width={width}
      color={color}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={style}
      wrapperClass=""
    />
  );
};

Loader.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Loader;
