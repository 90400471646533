import JSZip from "jszip";
import DOMPurify from "dompurify";

const htmlTemplate = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html dir="ltr" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en"><head><meta charset="UTF-8"><meta content="width=device-width, initial-scale=1" name="viewport"><meta name="x-apple-disable-message-reformatting"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta content="telephone=no" name="format-detection"><title>BusinesPhotoAI Purchase</title> <!--[if (mso 16)]><style type="text/css">     a {text-decoration: none;}     </style><![endif]--> <!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]--> <!--[if gte mso 9]><xml> <o:OfficeDocumentSettings> <o:AllowPNG></o:AllowPNG> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml>
<![endif]--><style type="text/css">.rollover:hover .rollover-first { max-height:0px!important; display:none!important; } .rollover:hover .rollover-second { max-height:none!important; display:block!important; } .rollover span { font-size:0px; } u + .body img ~ div div { display:none; } #outlook a { padding:0; } span.MsoHyperlink,span.MsoHyperlinkFollowed { color:inherit; mso-style-priority:99; } a.es-button { mso-style-priority:100!important; text-decoration:none!important; } a[x-apple-data-detectors] { color:inherit!important; text-decoration:none!important; font-size:inherit!important; font-family:inherit!important; font-weight:inherit!important; line-height:inherit!important; } .es-desk-hidden { display:none; float:left; overflow:hidden; width:0; max-height:0; line-height:0; mso-hide:all; } .es-button-border:hover > a.es-button { color:#ffffff!important; }
@media only screen and (max-width:600px) {.es-m-p0r { padding-right:0px!important } .es-m-p20b { padding-bottom:20px!important } *[class="gmail-fix"] { display:none!important } p, a { line-height:150%!important } h1, h1 a { line-height:120%!important } h2, h2 a { line-height:120%!important } h3, h3 a { line-height:120%!important } h4, h4 a { line-height:120%!important } h5, h5 a { line-height:120%!important } h6, h6 a { line-height:120%!important } h1 { font-size:30px!important; text-align:left } h2 { font-size:24px!important; text-align:left } h3 { font-size:20px!important; text-align:left } h4 { font-size:24px!important; text-align:left } h5 { font-size:20px!important; text-align:left } h6 { font-size:16px!important; text-align:left } .es-header-body h1 a, .es-content-body h1 a, .es-footer-body h1 a { font-size:30px!important } .es-header-body h2 a, .es-content-body h2 a, .es-footer-body h2 a { font-size:24px!important }
 .es-header-body h3 a, .es-content-body h3 a, .es-footer-body h3 a { font-size:20px!important } .es-header-body h4 a, .es-content-body h4 a, .es-footer-body h4 a { font-size:24px!important } .es-header-body h5 a, .es-content-body h5 a, .es-footer-body h5 a { font-size:20px!important } .es-header-body h6 a, .es-content-body h6 a, .es-footer-body h6 a { font-size:16px!important } .es-menu td a { font-size:14px!important } .es-header-body p, .es-header-body a { font-size:14px!important } .es-content-body p, .es-content-body a { font-size:14px!important } .es-footer-body p, .es-footer-body a { font-size:14px!important } .es-infoblock p, .es-infoblock a { font-size:12px!important } .es-m-txt-c, .es-m-txt-c h1, .es-m-txt-c h2, .es-m-txt-c h3, .es-m-txt-c h4, .es-m-txt-c h5, .es-m-txt-c h6 { text-align:center!important }
 .es-m-txt-r, .es-m-txt-r h1, .es-m-txt-r h2, .es-m-txt-r h3, .es-m-txt-r h4, .es-m-txt-r h5, .es-m-txt-r h6 { text-align:right!important } .es-m-txt-j, .es-m-txt-j h1, .es-m-txt-j h2, .es-m-txt-j h3, .es-m-txt-j h4, .es-m-txt-j h5, .es-m-txt-j h6 { text-align:justify!important } .es-m-txt-l, .es-m-txt-l h1, .es-m-txt-l h2, .es-m-txt-l h3, .es-m-txt-l h4, .es-m-txt-l h5, .es-m-txt-l h6 { text-align:left!important } .es-m-txt-r img, .es-m-txt-c img, .es-m-txt-l img { display:inline!important } .es-m-txt-r .rollover:hover .rollover-second, .es-m-txt-c .rollover:hover .rollover-second, .es-m-txt-l .rollover:hover .rollover-second { display:inline!important } .es-m-txt-r .rollover span, .es-m-txt-c .rollover span, .es-m-txt-l .rollover span { line-height:0!important; font-size:0!important } .es-spacer { display:inline-table } a.es-button, button.es-button { font-size:18px!important; line-height:120%!important }
 a.es-button, button.es-button, .es-button-border { display:inline-block!important } .es-m-fw, .es-m-fw.es-fw, .es-m-fw .es-button { display:block!important } .es-m-il, .es-m-il .es-button, .es-social, .es-social td, .es-menu { display:inline-block!important } .es-adaptive table, .es-left, .es-right { width:100%!important } .es-content table, .es-header table, .es-footer table, .es-content, .es-footer, .es-header { width:100%!important; max-width:600px!important } .adapt-img { width:100%!important; height:auto!important } .es-mobile-hidden, .es-hidden { display:none!important } .es-desk-hidden { width:auto!important; overflow:visible!important; float:none!important; max-height:inherit!important; line-height:inherit!important } tr.es-desk-hidden { display:table-row!important } table.es-desk-hidden { display:table!important } td.es-desk-menu-hidden { display:table-cell!important } .es-menu td { width:1%!important }
 table.es-table-not-adapt, .esd-block-html table { width:auto!important } .es-social td { padding-bottom:10px } .h-auto { height:auto!important } }@media screen and (max-width:384px) {.mail-message-content { width:414px!important } }</style>
 </head> <body class="body" style="width:100%;height:100%;padding:0;Margin:0"><div dir="ltr" class="es-wrapper-color" lang="en" style="background-color:#F6F6F6"> <!--[if gte mso 9]><v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t"> <v:fill type="tile" color="#f6f6f6"></v:fill> </v:background><![endif]--><table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;background-color:#F6F6F6"><tr>
<td valign="top" style="padding:0;Margin:0"><table class="es-header" cellspacing="0" cellpadding="0" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:100%;table-layout:fixed !important;background-color:transparent;background-repeat:repeat;background-position:center top"><tr><td align="center" style="padding:0;Margin:0"><table class="es-header-body" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px"><tr><td align="left" style="padding:0;Margin:0;padding-top:20px;padding-right:20px;padding-left:20px"> <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr>
<td style="width:180px" valign="top"><![endif]--><table class="es-left" cellspacing="0" cellpadding="0" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left"><tr><td class="es-m-p0r es-m-p20b" valign="top" align="center" style="padding:0;Margin:0;width:180px"><table width="100%" cellspacing="0" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td align="center" style="padding:0;Margin:0;display:none"></td> </tr></table></td></tr></table> <!--[if mso]></td><td style="width:20px"></td><td style="width:360px" valign="top"><![endif]--><table class="es-right" cellspacing="0" cellpadding="0" align="right" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right"><tr>
<td align="left" style="padding:0;Margin:0;width:360px"><table width="100%" cellspacing="0" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td style="padding:0;Margin:0;display:none" align="center"></td></tr></table></td></tr></table> <!--[if mso]></td></tr></table><![endif]--></td></tr></table></td></tr></table> <table class="es-content" cellspacing="0" cellpadding="0" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:100%;table-layout:fixed !important"><tr><td align="center" style="padding:0;Margin:0"><table class="es-content-body" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px"><tr>
<td align="left" style="padding:0;Margin:0;padding-top:20px;padding-right:20px;padding-left:20px"><table width="100%" cellspacing="0" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td valign="top" align="center" style="padding:0;Margin:0;width:560px"><table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td align="left" style="padding:0;Margin:0"><h1 align="left" style="Margin:0;font-family:arial, 'helvetica neue', helvetica, sans-serif;mso-line-height-rule:exactly;letter-spacing:0;font-size:30px;font-style:normal;font-weight:normal;line-height:36px;color:#333333">&nbsp;📸 Your AI Portrait is Almost Ready!</h1> </td></tr><tr>
<td align="left" style="padding:0;Margin:0"><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">Hey [Customer's Name],</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">​</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">Awesome news! 🚀 Your order has been successfully processed and our AI is now buzzing with creativity to generate your photorealistic portrait.</p>
 <p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">​</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">Here's the cool part: You won't have to wait long! Your custom AI portrait will be ready to wow you in just about 4 hours from now.&nbsp;</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">​</p>
 <p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">We're super thrilled you chose us for this AI-art adventure. At <strong>BusinessPhotoAI</strong>, we blend cutting-edge tech with art to create something truly unique and personal for you.</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">​</p>
 <p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">Got questions? Ideas? Just want to chat about AI and art? Drop us a line at <a target="_blank" href="mailto:info@hyperz.ai" style="mso-line-height-rule:exactly;text-decoration:underline;color:#2CB543;font-size:14px">info@hyperz.ai</a>. We love hearing from our community!</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">​</p>
 <p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">Keep an eye on your inbox. Your portrait donwload link is going to land there soon, and we can't wait to see what you think!</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">​</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">Cheers,</p><p align="left" style="Margin:0;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;letter-spacing:0;color:#333333;font-size:14px">The BusinessPhotoAI Team</p></td></tr></table></td></tr> </table>
</td></tr></table></td></tr></table> <table class="es-footer" cellspacing="0" cellpadding="0" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:100%;table-layout:fixed !important;background-color:transparent;background-repeat:repeat;background-position:center top"><tr><td align="center" style="padding:0;Margin:0"><table class="es-footer-body" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px"><tr><td align="left" style="Margin:0;padding-top:20px;padding-right:20px;padding-left:20px;padding-bottom:20px"> <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr>
<td style="width:270px" valign="top"><![endif]--><table class="es-left" cellspacing="0" cellpadding="0" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left"><tr><td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:270px"><table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td align="center" style="padding:0;Margin:0;font-size:0"><img src="https://efcxfig.stripocdn.email/content/guids/CABINET_b947f477de02da7d8402bb2a0a1b60de85804937536165e00dd3378293bf26c2/images/businessphoto_logo_better_2.png" alt="" width="270" class="adapt-img" style="display:block;font-size:14px;border:0;outline:none;text-decoration:none"></td> </tr></table></td></tr></table> <!--[if mso]></td><td style="width:20px"></td>
<td style="width:270px" valign="top"><![endif]--><table class="es-right" cellspacing="0" cellpadding="0" align="right" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right"><tr><td align="left" style="padding:0;Margin:0;width:270px"><table width="100%" cellspacing="0" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px"><tr><td style="padding:0;Margin:0;display:none" align="center"></td></tr></table></td></tr></table> <!--[if mso]></td></tr></table><![endif]--></td></tr></table></td></tr></table></td></tr></table></div></body></html>
`;

const AWS = require("aws-sdk");
const REACT_APP_DEBUG_MODE = process.env.REACT_APP_DEBUG_MODE == "true";
const REACT_APP_TEST_MODE = process.env.REACT_APP_TEST_MODE == "true";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "us-east-1",
});

const lambda = new AWS.Lambda();

export const get_pricing = async (email, token) => {
  const url = REACT_APP_TEST_MODE
    ? "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/web_get_has_paid_test"
    : "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/web_get_has_paid";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email: DOMPurify.sanitize(email) }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const { pricing } = data;
    return pricing;
  } catch (error) {
    console.error(
      "Error calling get_has_paid API:",
      error.response ? error.response.data : error.message
    );
  }
  return "none";
};

export async function startTraining(payload, token) {
  // TODO: Not authenticated, remove later
  if (REACT_APP_DEBUG_MODE) console.log("STARTTRAINING IS BEING CALLED");
  const apiUrl = REACT_APP_TEST_MODE
    ? "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/start_training_test"
    : "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/start_training";

  if (REACT_APP_DEBUG_MODE) console.log("Token: ", token);
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    if (REACT_APP_DEBUG_MODE)
      console.log("Response from the API:", responseData);
    return responseData; // Or handle the response data as needed
  } catch (error) {
    if (REACT_APP_DEBUG_MODE)
      console.error("Error adding job to queue:", error);
    throw error;
  }
}

export async function setHasPaidServer(
  email,
  user_id,
  has_paid,
  image_package,
  pricing,
  token
) {
  const url =
    "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/web_set_has_paid";
  const body = {
    email: DOMPurify.sanitize(String(email)),
    user_id: DOMPurify.sanitize(String(user_id)),
    has_paid: DOMPurify.sanitize(String(has_paid)),
    package: DOMPurify.sanitize(String(image_package)),
    pricing: DOMPurify.sanitize(String(pricing)),
  };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (REACT_APP_DEBUG_MODE)
        console.log(
          `Added to DynamoDB database with web_set_has_paid with response code ${response.status}`
        );
    })
    .catch((error) => console.error(error));
}

// Function to upload a single file
export async function uploadFile(file, presignedPostData) {
  // Log the presigned post data
  if (REACT_APP_DEBUG_MODE)
    console.log("Presigned Post Data: ", presignedPostData);

  // Destructure the url and fields from the presigned post data
  const { url, fields } = presignedPostData;

  // Create a new FormData object
  const formData = new FormData();

  if (REACT_APP_DEBUG_MODE) console.log("Upload URL uploadFILE: ", url);

  // Append the fields from the presigned post data to the form
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value);
  });

  // Append the file to be uploaded with the 'file' key (as specified by S3 POST policy).
  formData.append("file", file);
  if (REACT_APP_DEBUG_MODE) console.log("Form Data UPLOAD: ", formData);

  // Send a POST request to the presigned URL with the form data
  return fetch(url, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      // Check if the upload was successful
      if (response.ok || response.status === 204) {
        if (REACT_APP_DEBUG_MODE) console.log("File uploaded successfully");
      }
    })
    .catch((error) => {
      // Log any errors that occur during the upload
      console.error("Error uploading file:", error);
      throw error;
    });
}

// Function to upload multiple files
export async function uploadFiles(images, presignedPostDataArr) {
  // Create an array to store the promises
  const uploadPromises = [];

  // Loop over the images and presigned post data arrays
  for (let i = 0; i < images.length; i++) {
    // Call the uploadFile function for each image and corresponding presigned post data
    uploadPromises.push(uploadFile(images[i], presignedPostDataArr[i]));
  }

  // Await all the upload promises
  await Promise.allSettled(uploadPromises);
}

// Function to call the upload API
export async function callUploadAPI(userId, jobId, fileArray, images, token) {
  if (REACT_APP_DEBUG_MODE) console.log("Call upload API!");

  // Define the API URL
  const url = REACT_APP_TEST_MODE
    ? "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/upload_file_test"
    : "https://cmrqve17f8.execute-api.us-east-1.amazonaws.com/upload_file";

  // Define the request body
  const body = {
    user_id: DOMPurify.sanitize(String(userId)),
    job_id: DOMPurify.sanitize(String(jobId)),
    file_names: fileArray,
  };

  // Convert the body to a JSON string
  const bodyString = JSON.stringify(body);

  if (REACT_APP_DEBUG_MODE) console.log("User ID: ", userId);
  if (REACT_APP_DEBUG_MODE) console.log("Job ID: ", jobId);
  if (REACT_APP_DEBUG_MODE) console.log("File Array: ", fileArray);
  if (REACT_APP_DEBUG_MODE) console.log("Body: ", bodyString);

  try {
    // Send a POST request to the API
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if the request was successful
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the response data as JSON
    const responseDataArr = await response.json();
    console.log("Response Data: ", responseDataArr);
    if (responseDataArr) {
      if (REACT_APP_DEBUG_MODE)
        console.log("API call response upload_test:", responseDataArr);
      // Call the uploadFiles function with the images and response data
      await uploadFiles(images, responseDataArr);
    }
  } catch (error) {
    // Log any errors that occur during the API call
    console.error(
      "Error calling upload API:",
      error.response ? error.response.data : error.message
    );
  }
}

// Function to upload images to S3
export const uploadImagesToS3 = async (images, userId, jobId, token) => {
  if (REACT_APP_DEBUG_MODE) console.log("Upload images to s3!");

  // Create an array to hold the file names
  let fileArray = [];

  // Add the name of each image to the file array
  for (const image of images) {
    if (REACT_APP_DEBUG_MODE) console.log("Image: ", image.name);
    fileArray.push(image.name);
  }
  if (REACT_APP_DEBUG_MODE) console.log("File Array: ", fileArray);

  // Call the upload API with the user ID, job ID, file array, images, and token
  await callUploadAPI(userId, jobId, fileArray, images, token);
};
