import React from "react";
import { Field, Formik, useField } from "formik";
import maleImage from "../assets/man_image.jpg";
import femaleImage from "../assets/woman.jpg";
import "./GenderStep.css"; // Assume you have a CSS file for styling

const GenderButton = ({ label, imgSrc, ...props }) => {
  const [field, , helpers] = useField(props);
  const { value } = field;
  const { setValue } = helpers;

  // Toggle the selected class based on whether the button's value matches the field value
  const isSelected = value === props.value;

  return (
    <button
      type="button"
      className={`gender-button ${isSelected ? "selected" : ""}`}
      onClick={() => setValue(props.value)}
      style={{ fontFamily: "Thiccboi, sans-serif" }}
    >
      <img src={imgSrc} alt={label} className="gender-image" />
      <h2>{label}</h2>
    </button>
  );
};

const GenderStep = ({ values }) => {
  return (
    <div className="gender-step">
      <h2>Please select your gender:</h2>
      <div className="gender-buttons">
        <GenderButton label="Male" name="gender" value="M" imgSrc={maleImage} />
        <GenderButton
          label="Female"
          name="gender"
          value="F"
          imgSrc={femaleImage}
        />
      </div>
    </div>
  );
};

export default GenderStep;
