import React from "react";
import { Box, Grid, Typography, Paper, List, ListItem } from "@mui/material";

function Pricing() {
  return (
    <Box sx={{ flexGrow: 1, textAlign: "center", my: 4 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              height: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">60 images</Typography>
            <List>
              <ListItem>Detail for 60 images</ListItem>
              {/* More details if necessary */}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              height: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">120 images</Typography>
            <List>
              <ListItem>Detail for 120 images</ListItem>
              {/* More details if necessary */}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              height: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">180 images</Typography>
            <List>
              <ListItem>Detail for 180 images</ListItem>
              {/* More details if necessary */}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Pricing;
