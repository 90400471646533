import React from "react";
import PropTypes from "prop-types";
import "./Button.css"; // Assume you have a CSS file for styling

const CustomButton = ({ label, disabled, onClick, type, style, small }) => (
  <button
    className={small ? "button-black-small" : "button-black"}
    type={type}
    style={style}
    disabled={disabled}
    onClick={onClick}
  >
    {label}
  </button>
);

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit"]),
  style: PropTypes.object,
};

CustomButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  type: "button",
  style: {},
};

export default CustomButton;
