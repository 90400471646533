import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Pricing from "./components/Pricing";
import GetYourPhotos2 from "./components/GetYourPhotos2";
// Other imports...

function App() {
  return (
    <Router>
      <PageLayout>
        <Routes>
          <Route path="/" element={<GetYourPhotos2 />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/get-your-photos" element={<GetYourPhotos2 />} />
          {/* Define other routes here */}
        </Routes>
      </PageLayout>
    </Router>
  );
}

function PageLayout({ children }) {
  const location = useLocation();
  const showHeaderAndFooter = location.pathname !== "/get-your-photos";

  return <>{children}</>;
}

export default App;
