import React from "react";
import "./SummaryStep.css";
import goodExample1 from "../assets/example_001.jpg";
import goodExample2 from "../assets/example_002.jpg";
import goodExample3 from "../assets/example_006.jpg";
import badExample1 from "../assets/bad_01.jpg";
import badExample2 from "../assets/bad_02.jpg";
import badExample3 from "../assets/bad_06.jpg";
const SummaryStep = ({ values }) => {
  return (
    <div className="summary-step">
      <h2>Summary</h2>
      <div className="summary-content">
        {/* Display each form field value */}
        {/* <p>
          <strong>Pricing Option:</strong> {values.pricingOption}
        </p> */}
        <p>
          <strong>Gender:</strong> {values.gender}
        </p>
        {/* Render list of photos */}
        <div>
          <h3>Uploaded Photos</h3>
          <div className="uploaded-photos">
            {values.photos.map((photo, index) => (
              <img
                key={index}
                src={URL.createObjectURL(photo)}
                alt={`Uploaded ${photo.name}`}
                className="uploaded-photo"
              />
            ))}
          </div>
        </div>
      </div>
      <h2>What To Expect</h2>
      <p>
        The AI we employ for generating realistic photos may inadvertently
        produce artifacts, inaccuracies, and imperfections in the resulting
        images, which is beyond our control. Therefore, please be aware of and
        accept this risk before proceeding.{" "}
      </p>
      <h2>Good Examples</h2>
      <div className="image-grid">
        <img src={goodExample1} alt={"label"} className="good-image" />
        <img src={goodExample2} alt={"label"} className="good-image" />
        <img src={goodExample3} alt={"label"} className="good-image" />
      </div>
      <h2>Bad Examples</h2>
      <img src={badExample1} alt={"label"} className="good-image" />
      <img src={badExample2} alt={"label"} className="good-image" />
      <img src={badExample3} alt={"label"} className="good-image" />
    </div>
  );
};

export default SummaryStep;
